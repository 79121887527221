<template>
  <div class="tutorial submain-div">

    <div class="tutorial-item">
      <div class="tutorial-item-left">
        <img class="img" src="../../assets/img/logo3.png" alt="">
      </div>
      <div class="tutorial-item-right">
    


        <span class="item-title">教程</span>
        <embed v-if="false" :src="pdfUrl" type="application/pdf" width="100%" height="600px" />
   
      <i-file-doc theme="outline" size="48" fill="#409EFF" />

      <a target="_blank" href="https://www.qisi.es/download/奇思餐饮系统使用教程.pdf">奇思餐饮标准版</a>
      
  
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'tech',
  data() {
    return {
      pdfUrl:"https://www.qisi.es/download/奇思餐饮系统使用教程.pdf",
    };
  },
  computed: {
  
  },
  watch: {
   
  },
  methods: {
    updateMessage() {
  
    }
  },
  created() {
  
  },
  mounted() {
  
  },
  beforeMount() {

  },
  updated() {
   
  },
  beforeUpdate() {

  },
  beforeDestroy() {
 
  },
  destroyed() {

  },
  beforeRouteEnter(to, from, next) {

    next();
  },
  beforeRouteLeave(to, from, next) {
 
    next();
  }
};
</script>

<style lang="less"  scoped>


.tutorial{
  min-height: 700px;
}

.tutorial-item{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  .tutorial-item-left{
    width: 80%;

    .img{
      max-width: 100%;
    }
  }

  .tutorial-item-right{
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  a{
    text-decoration: none;
    font-size: 22px;
    color: var(--main-logo-color);
  }

  .item-title{
    font-size: 30px;
    color: var(--main-color-2);
  }

}


@media (max-width:991px) {

  .tutorial-item{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .tutorial-item-left{
    width: 100%;

    .img{
      max-width: 100%;
    }
  }

  .tutorial-item-right{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

  }

 

}

}
</style>

