<template>
  <div class="display">
    <embed class="embed" :src="pdfUrl" type="application/pdf" width="100%" :height="screenHeight" />
  </div>
</template>

<script>
export default {
  name: 'template',
  data() {
    return {
      pdfUrl:"https://www.qisi.es/download/奇思餐饮系统使用教程.pdf",
      screenHeight: window.innerHeight + 'px' // 获取屏幕高度并转换为像素值
    };
  },
  computed: {
  
  },
  watch: {
   
  },
  methods: {
    updateMessage() {
  
    },
    updateScreenHeight() {
      this.screenHeight = window.innerHeight + 'px'; // 更新屏幕高度值
    }
  },
  created() {
    window.addEventListener('resize', this.updateScreenHeight); // 监听窗口大小变化
  },
  mounted() {
  
  },
  beforeMount() {

  },
  updated() {
   
  },
  beforeUpdate() {

  },
  beforeDestroy() {
 
  },
  destroyed() {
    window.removeEventListener('resize', this.updateScreenHeight); // 组件销毁时移除监听器
  },
  beforeRouteEnter(to, from, next) {

    next();
  },
  beforeRouteLeave(to, from, next) {
 
    next();
  }
};
</script>

<style lang="less"  scoped>
.display{
  overflow: hidden;
}


</style>

