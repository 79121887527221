<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
      windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
    }
  },
  created() {

    this.setwidth()
   // this.$message.success("屏幕：" + this.$store.state.screenStat + document.documentElement.clientWidth)
    //===========================下面是解决刷新页面丢失vuex数据
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
    }
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
    //===========================下面是解决刷新页面丢失vuex数据
  },

  watch: {
    windowHeight(val) {
      let that = this // console.log("实时屏幕高度：",val, that.windowHeight );
    },
    windowWidth(val) {

      let that = this
      if (val > this.$store.state.widthValue) {

        this.$store.commit('edit', 'big')
      } else

        this.$store.commit('edit', 'small')

      console.log('实时屏幕宽度：', val, that.windowHeight,  that.$store.state.screenStat,this.$store.state.widthValue)
      this.$store.commit('getWidth', val)
    },
  },
  methods: {
    setwidth() {
      var that = this

      this.$store.state.screenStat = null
      if (document.documentElement.clientWidth >= this.$store.state.widthValue) {
        this.$store.commit('edit', 'big')
      } else if (document.documentElement.clientWidth < this.$store.state.widthValue) {

        this.$store.commit('edit', 'small')
      } // <!--把window.onresize事件挂在到mounted函数上-->
      window.onresize = () => {
        return (() => {
          window.fullHeight = document.documentElement.clientHeight
          window.fullWidth = document.documentElement.clientWidth
          that.windowHeight = window.fullHeight // 高
          that.windowWidth = window.fullWidth // 宽
        })()
      }



    },
  },
}
</script>

<style>
#app {




  

}



.el-message--success .el-message__content {
  font-size: 0.25rem;
  font-weight: 700;
}

.el-message--error .el-message__content {
  font-size: 0.25rem;
  font-weight: 700;
}
</style>
