module.exports = {
   

    nav:{
        home:'首页',
        services:'服务',
        contact:'联系',
        goods:'产品介绍',
        about:'关于我们',


        tech:'教程',
        support:'技术支持',
        try:'在线尝试',
        drive:'驱动下载',
        remotely:'远程协助',
        clientDisplay:"客户展示",


        classic:'餐饮标准版',
        avanced:"餐饮进阶版",
        storeclassic:"百货标准版",
    },
    footer:{
        contact:"联系我们"
    }




}