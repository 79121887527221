import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        
        userInfo: {
            username: '',
            token: '',
            role: '',
        },
        menuList: [],
        cartList: [],
        takeawayList: [],
        takeawayModel: '',
        cartStoreList: '', //总菜单
        screenStat: 'small',
        windowWidth: null,
        cartDisplayInf: {
            activeImg: '',
            activeName: '',
            activeInfo: '',
        }, /// cart 页面 显示的主标题
        cartDisplayData: '', /// cart 页面 显示的主标题 的数据
        takeawayStatus: {
            model: 'tienda',
        },
        lang: 'es',
        menuSelectSmall: '',
        userName: '',
        selectId: '', //通过界面添加ID

        controlSelect: 'control',
        cartSelect:'cart',//根据这个判断 是MENU 还是 CART cart menu
        isMenu:'cart',//目前是根据这个 true false来决定
        isTouch:'false',
        isFocus:'false',
        isCreditCart:true,
        navSeletecId:'0',
        isquick:true,
        autoSelectMenu:true,

        smallPx:"768",//no use

        widthValue:"991",
        

    },

    mutations: {
        
        saveUserInfo(state, user) {
            state.userInfo.user = user.username
            state.userInfo.role = user.role
            state.userInfo.token = user.token
        },
        getDisplayIndex(state, obj) {
            //  console.log(data)
            state.cartDisplayInf.activeImg = obj.activeImg;
            state.cartDisplayInf.activeName = obj.activeName;
            state.cartDisplayInf.activeInfo = obj.activeInfo;
        },
        getcartDisplayData(state, data) {
            //  console.log(data)
            state.cartDisplayData = data;

        },
        changeNameSmall(state, name) {
            state.menuSelectSmall = name;
        },

        getMenuList(state, data) {
            //  console.log(data)
            state.menuList = data;
        },
        getcartList(state, data) {
            //  console.log(data)
            state.cartList = data;
        },
        getcartStoreList(state, data) {
            //  console.log(data)
            state.cartStoreList = data;
        },
        delcartStoreList(state, data, index) {
            //  console.log(data)
            data.amount -= 1;
            if (data.amount <= 0)
                return state.takeawayList.splice(index, 1)
            state.cartStoreList = data;
        },
        addTakeawayList(state, data) {
            var isExist = ''
            var index = ''
                //console.log(data)
            state.takeawayList.forEach((item, ind) => {
                if (item.cartId == data.cartId) {
                    isExist = true
                    index = ind
                    return
                }
            })
            if (isExist) {
                state.takeawayList[index].amount += 1
                return
            }


            var newMenu = state.cartStoreList.filter(function(keys, index) {
                return keys.cartId === data.cartId
            })


            newMenu[0].amount = 1;

            state.takeawayList.push(newMenu[0])
        },
        deleteTakeawayList(state, index) {
            state.takeawayList.splice(index, 1)
        },
        editakeawayList(state, date) {
            // state.takeawayList.splice(index, 1)
        },
        changeLnag(state, value) {
            state.lang = value
        },
        getWidth(state, valor) {
            state.windowWidth = valor
        },
        edit(state, value) {
            state.screenStat = value
        },
        addIdtoCadt(state, value) {
            state.selectId = value.cartId
                // console.log(state.selectId)
        }
        // add()
    },
    actions: {

        // 将菜单列表扁平化形成权限列表
        getPermissionList({ state }) {
            console.log('进入vuex actions')
            return new Promise(async(resolve) => {
                let permissionList = []
                    // 将菜单数据扁平化为一级
                function flatNavList(arr) {

                    arr.forEach(item => {
                        ///添加一级分类
                        permissionList.push(item)
                            //查询是否存在二级分类
                        if (item.children != undefined)
                            if (item.children.length > 1) {
                                item.children.forEach(item1 => {
                                    permissionList.push(item1)
                                })
                            }
                    })
                }
                //向服务器获取权限表
                const { data: res } = await axios({
                    method: 'get',
                    url: '/my/getUserRoleList',

                })


                //  console.log(res.data)
                flatNavList(res.data)
                    //  console.log(permissionList)
                resolve(permissionList)

                //  resolve(state.staticRoute)

            })
        }


    },
    modules: {},
    getters: {

    }
})