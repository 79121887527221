<template>
  <div class="about submain-div">


    <!-- 公司简介 -->
    <div class="company-introduction">

      <h1 class="global-title">奇思科技：您的收银智慧伙伴</h1>
      <p>欢迎来到奇思科技，您专属的收银系统和软件解决方案提供商。我们自主研发餐饮收银系统和百货收银系统，还包括精心设计的网站。我们引以为豪的优势体现在以下几个方面：</p>
      <p><span class="title">在线试用：</span>我们为您提供在线试用的机会，让您亲身体验我们的系统的功能和便捷性。</p>
      <p><span class="title">完善的文档：</span>我们拥有更为详尽的文档，为您提供清晰的操作指南和技术支持，确保您能够轻松上手并高效使用。</p>
      <p><span class="title">开发人员直接对接：</span>我们的开发团队直接与您对接，确保您的需求能够准确被理解并及时得到响应。</p>
      <p><span class="title">专注于餐饮POS和百元店收银：</span>我们的主要专注领域是餐饮POS收银系统和百元店收银系统。通过不断创新和优化，我们助您实现高效的业务管理。</p>
      <p><span class="title">客户合作展示</span>：我们与多个合作伙伴紧密合作，为不同行业的客户提供定制化解决方案，共同创造更大的价值。</p>
      <p><span class="title">品质保障：</span>我们为您提供所有设备，保证原装正品，硬件质保期达2年。您可以安心使用，无需担心后续问题。</p>
      <p>奇思科技致力于通过创新技术和专业团队，为您提供卓越的收银解决方案。我们以客户的需求为导向，不断提升我们的产品和服务，帮助您实现业务的成功和增长。</p>
      <p>如果您有任何疑问或合作意向，欢迎随时与我们联系。让奇思科技成为您的收银智慧伙伴，共同开创更美好的未来。</p>

    </div>





    <!-- 联系方式 -->
    <div id="contact">
      <div class="contact-container">
        <h2  class="contact-heading global-title">联系我们</h2>
        <div class="contact-info">
          <div class="contact-item">

            <span class="contact-text"> <i-phone-telephone theme="outline" size="24" />: 0034-624070261</span>
          </div>
          <div class="contact-item">

            <p class="contact-text"><i-mail theme="outline" size="24" />: qisisoft@gmail.com</p>
          </div>

          <div>
            <i-wechat theme="outline" size="24" />
            <img src="../../assets/img/QT.png" alt="">

          </div>

        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'about',
  data() {
    return {

    };
  },
  computed: {

  },
  watch: {

  },
  methods: {

  },
  created() {

  },
  mounted() {

  },
  beforeMount() {

  },
  updated() {

  },
  beforeUpdate() {

  },
  beforeDestroy() {

  },
  destroyed() {

  },
  beforeRouteEnter(to, from, next) {

    next();
  },
  beforeRouteLeave(to, from, next) {

    next();
  }
};
</script>

<style lang="less"  scoped>
.about {
  display: flex;
  flex-direction: row;
}


.contact {
  width: 60%;

  .contact-container {

    margin: 0 auto;
    padding: 40px;


  }

  .contact-heading {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .contact-info {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contact-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 40px;
  }

  .contact-icon {
    font-size: 24px;
    margin-right: 10px;
  }

  .contact-text {
    font-size: 16px;
    margin: 0;
  }
}






.company-introduction {
  /* 样式可以根据您的需要进行调整 */

  padding: 20px;
  width: 60%;
}

.company-introduction h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.company-introduction p {
  font-size: 16px;
  margin-bottom: 15px;
}

.title {
  font-weight: 800;
}

@media (max-width:991px) {
  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .company-introduction {
    width: 100%;
    padding: 20px;
  }

}

@media (max-width:768px) {
  .about {
    display: flex;
    flex-direction: column;
  }

  .company-introduction {
    width: 100%;
  }

}
</style>

