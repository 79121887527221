module.exports = {

    mainPage:{
        login:'login',
        quit:'quit',
        rememberPassword:'remember Password',
        fullScreen:'fullScreen',
        fullScrrenQuiet:'fullQuiet'
    },

    tableManagement:{
        tableOrder:'Pedidos',
        tableConfig:'mesa conf',
        openCaja:'Abrir Caja',
        addTable:'',
        inputPassword:'introducir la contrasena',
   

    },
    globalInfo:{
        cancel:'Cancela',
        accept:'Acepta',
        edit:'Edita',
        delete:'Borra',
        search:'Busca',
        prompt:'Avisa',
        isDelete:'Borra o No',
    },

    tablePage:{
        print:'Print',
        save:'Guarda',
        savePrint:'Save Print',

        operate:'Funciona',
        history:"Historia",
        menu:'Cart',
        myMenu:'Menu',

        settlement:'Cobra',
        resetPrint:'resetPrint',
        return:'devolve',
        inputId:'inputId',
        split:'Separado',
        changeTable:'Mov mesa',
        tableDiscount:'Descuento',
        openCaja:'Abrir Caja',
        takeawaySearch:'lleva search',
        takeawayInfo:'lleva Info',
        facturaInfo:'factura Info',
        facturaPrint:'factura Print',
        saveReturn:'Save Return',

        displayCart:'Cart/Menu',
        displayMenu:'See Menu',
        exhibit:'exhibit',
        saveMenu:'Menu Save',
        menuSavePrint:"Menu Print",

        name:'Nombre',
        price:'Precio',
        quantity:'Cantidad',


        cartDiscount:'cartDiscount',
        subPrice:"SubTotal;",
        totalPrice:'Total',
        switchCrash:'Efectivo',

        payable:'Pagadero',
        change:'Cambia',

        creditCard:'Targeta',
        cash:'Efectivo',

        addDandwriting:'addEscritura'


    },




    menu: {
        home: "casa"
    },
    content: {
        main: "this is content"
    },
    homeMenuList: [{
            name: 'INICIO',
            id: '1',
            path: '/Home/INICIO',
        },
        {
            name: 'CARTA',
            id: '2',
            path: '/Home/Cart',
        },

        {
            name: 'CONTACTO',
            id: '3',
            path: '/Home/CONTACTO',
        },
        {
            name: 'NOSOTROS',
            id: '4',
            path: '/Home/NOSOTROS',
        },
    ],

    home: {
        register: 'Registrarse',
        login: 'Iniciar sesión',
    },
    cart: {
        add: 'añadir',
        myShopCart: 'Mi carrito',
    }
}