<template>
  <div class="submain-div">
  
  
    <router-view></router-view>


  </div>
</template>

<script>
export default {
  name: 'goods',
  data() {
    return {
    
    };
  },
  computed: {
   
  },
  watch: {
    
  },
  methods: {
    updateMessage() {
  
    }
  },
  created() {

  },
  mounted() {
   
  },
  beforeMount() {

  },
  updated() {

  },
  beforeUpdate() {

  },
  beforeDestroy() {
   
  },
  destroyed() {

  },
  beforeRouteEnter(to, from, next) {
 
    next();
  },
  beforeRouteLeave(to, from, next) {

    next();
  }
};
</script>

<style lang="less"  scoped>


.main{
  display: flex;
  flex-direction: column;

}


.goods-item{
  display: flex;
  flex-direction: row;
  height: 400px;
  
}


</style>

