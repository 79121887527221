import Vue from 'vue'
import Router from 'vue-router'


import App from './App.vue'
import store from './store'
import router from './router/index.js'




//导入 插件的element ui
//import './plugins/element.js'


//icon park
import {install} from '@icon-park/vue/es/all';
install(Vue, 'i'); // use custom prefix 'i', eg: icon is People, name is i-people.
import '@icon-park/vue/styles/index.css';



import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
const messages = ['success', 'warning', 'info', 'error'];
messages.forEach(type => {
  ElementUI.Message[type] = options => {
    if (typeof options === 'string') {
      options = {
          message: options
      };
      // 默认配置
      options.duration = 500;
      options.showClose = true;
    }
    options.type = type;
    return ElementUI.Message(options);
  }

})

Vue.use(ElementUI);

import Timeline from './plugins/timeline/index.js'
import TimelineItem from './plugins/timeline-item/index.js'
Vue.use(Timeline)
Vue.use(TimelineItem)



//重点，Added non-passive event listener to a scroll-blocking 'mousewheel' event. https://blog.csdn.net/weixin_40716786/article/details/90045802
import 'default-passive-events'


import './assets/css/global.css'
///阿里 ICON 引入是CSS
import './assets/fonts/iconfont.css'
///展示进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
/*
import 'vue-googlemaps/dist/vue-googlemaps.css'
import VueGoogleMaps from 'vue-googlemaps'*/
//屏幕自适应   https://blog.csdn.net/qq_42392522/article/details/82977929

//px2 rem https://xiaozhuanlan.com/topic/2471609583

//自适应

//import 'lib-flexible/flexible.js'

//import 'lib-flexible'

import TreeTable from 'vue-table-with-tree-grid'


//
//全屏组件
//https://blog.csdn.net/qq_43787947/article/details/123655032
//npm install --save-dev screenfull@5.1.0 换成5.1.0 
//import ScreenFull from 'screenfull/dist/screenfull'

//import ScreenFull from 'screenfull/dist/screenfull'

/// 多语言
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: 'zh', // 语言标识 
    messages: {
        'es': require('./assets/lang/es'),
        'en': require('./assets/lang/en'),
        'ca': require('./assets/lang/ca'),
        'zh': require('./assets/lang/zh'),
    }
})


//vue 导出 excel https://blog.csdn.net/qq_42221334/article/details/99566915

/*
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import TouchKeyBoard from './components/common/touch-keyboard.vue'
Vue.component('TouchKeyBoard', TouchKeyBoard)

*/


//coolies 
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)



import axios from 'axios'
axios.defaults.timeout = 6000



//前端加密
//const Base64 = require("js-base64").Base64



/////stock 老版本这个可用
//import VueSocketIO from 'vue-socket.io'
//import SocketIO from "socket.io-client"



//const myip="192.168.3.210" //新电脑
//const myip="192.168.3.210" //路由端
//var myip="192.168.3.35"//笔记本
//var myip="192.168.3.62" //caja电脑
const myip="www.qisi.es"//服务器


//const myip="localhost"//本地

/*
Vue.use(new VueSocketIO({
    debug: false,
    connection: SocketIO('ws://'+myip+':25000') //测试模式
    
}))
*/



axios.defaults.baseURL = 'https://'+myip+':10300/' //服务器
//axios.defaults.baseURL = 'http://'+myip+':10500/' //本地




axios.interceptors.request.use(config => {
    // console.log(config)
    NProgress.start()
    config.headers.Authorization = window.sessionStorage.getItem('token')
    return config
})

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}




/*
Vue.use(VueGoogleMaps, {
    load: {
        //填入申请的apiKey账号
        apiKey: 'AIzaSyCM3uGc5LfGcvTz1v2W-5CRvmAhjrz81X4',
        libraries: ['places'],
        useBetaRenderer: false,
        //languege:es,
    },
})
*/


Vue.component('tree-table', TreeTable)

axios.interceptors.response.use(

    response => {
        NProgress.done()
        return response
    },
    error  => {
        NProgress.done()
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    // 返回 401 清除token信息并跳转到登录页面

                    window.sessionStorage.clear();
                    router.replace({
                        path: '/Home/Login'
                    })

            }
        }
        console.log("error")
       // console.log(error)
        return Promise.reject(error?.response?.data) // 返回接口返回的错误信息
    })


Vue.prototype.$http = axios
Vue.config.productionTip = false





new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')