<template>
	<div>
		<div class="showImg">

			<img class="img" @mouseover="changeInterval(true)" @mouseleave="changeInterval(false)" v-for="(item) in imgArr"
				:key="item.id" :src="item.url" :alt="imgPath + item.url" v-show="item.id === currentIndex">

			<div @click="clickIcon('up')" class="iconDiv icon-left">
				<i class="el-icon-caret-left"></i>
			</div>

			<div @click="clickIcon('down')" class="iconDiv icon-right">
				<i class="el-icon-caret-right"></i>
			</div>

			<div class="banner-circle">
				<ul>
					<li @click="changeImg(item.id)" v-for="(item) in imgArr" :key="item.id"
						:class="item.id === currentIndex ? 'active' : ''"></li>
				</ul>
			</div>


		</div>
		<div class="main-div">
			<my-tech>

			</my-tech>
		</div>
		<div>

		</div>
	</div>
</template>



<script>
import page1 from '../../assets/img/logo1.jpg'
import page2 from '../../assets/img/logo2.jpg'
import page3 from '../../assets/img/logo3.png'
import page4 from '../../assets/img/logo4.png'

import tech from './tech.vue';
export default {
	name: 'home',
	components: {
    'my-tech': tech
  },
	data() {
		return {
			currentIndex: 0,//当前所在图片下标
			timer: null,//定时轮询
			imgArr: [
				{
					id: 0,
					url: page1,
				}, {
					id: 1,
					url: page2,
				}, {
					id: 2,
					url: page3,
				}, {
					id: 3,
					url: page4,
				},
			],
			imgPath: "../../../public/static/img/"
		}
	},
	methods: {
		//开启定时器
		startInterval() {
			// 事件里定时器应该先清除在设置，防止多次点击直接生成多个定时器
			clearInterval(this.timer);
			this.timer = setInterval(() => {
				this.currentIndex++;
				if (this.currentIndex > this.imgArr.length - 1) {
					this.currentIndex = 0
				}
			}, 3000)
		},
		// 点击左右箭头
		clickIcon(val) {
			if (val === 'down') {
				this.currentIndex++;
				if (this.currentIndex === this.imgArr.length) {
					this.currentIndex = 0;
				}
			} else {
				/* 第一种写法
				this.currentIndex--;
				if(this.currentIndex < 0){
					this.currentIndex = this.imgArr.length-1;
				} */
				// 第二种写法
				if (this.currentIndex === 0) {
					this.currentIndex = this.imgArr.length;
				}
				this.currentIndex--;
			}
		},
		// 点击控制圆点
		changeImg(index) {
			this.currentIndex = index
		},
		//鼠标移入移出控制
		changeInterval(val) {
			if (val) {
				clearInterval(this.timer)
			} else {
				this.startInterval()
			}
		}
	},
	//进入页面后启动定时轮询
	mounted() {
		this.startInterval()
	},


	beforeMount() {

	},
	updated() {

	},
	beforeUpdate() {

	},
	beforeDestroy() {
		clearInterval(this.timer)

	},
	destroyed() {

	},
	beforeRouteEnter(to, from, next) {

		next();
	},
	beforeRouteLeave(to, from, next) {

		next();
	}
};
</script>

<style lang="less"  scoped>
* {
	padding: 0;
	margin: 0;
}

/* 清除li前面的圆点 */
li {
	list-style-type: none;
}

.showImg {
	position: relative;
	width: 100%;
	height: 720px;
	overflow: hidden;
}

/* 轮播图片 */
.showImg img {
	width: 100%;
	height: 100%;
}

/* 箭头图标 */
.iconDiv {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 30px;
	height: 30px;
	border: 1px solid #666;
	border-radius: 15px;
	background-color: rgba(125, 125, 125, .2);
	line-height: 30px;
	text-align: center;
	font-size: 25px;
	cursor: pointer;
}

.iconDiv:hover {
	background-color: white;
}

.icon-left {
	left: 10px;
}

.icon-right {
	right: 10px;
}

/* 控制圆点 */
.banner-circle {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

}

.banner-circle ul {
	margin: 0 50px;
	height: 100%;
	text-align: right;
}

.banner-circle ul li {
	display: inline-block;
	width: 14px;
	height: 14px;
	margin: 0 5px;
	border-radius: 7px;
	background-color: rgba(125, 125, 125, .8);
	cursor: pointer;
}

.active {
	background-color: black !important;
}


.main-div{
	max-width: 1200px;
	padding: 10px;
	margin: auto;

}

@media (max-width:991px) {

	.showImg {
		width: 100%;
		height: auto;
		margin: 0px;
	}

	img .img {
		width: 100%;
		/* 设置图像宽度为父容器的100% */
		height: auto;
		/* 自动计算高度以保持宽高比 */

	}

	.banner-circle {
		bottom: 10px;
	}
}
</style>

