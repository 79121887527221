<template>
  <div class="support submain-div">
    <h1 class="global-title">
      支持和常见问题
    </h1>

    <div>
      <h3>
        餐饮标准版跟进阶版有什么区别
      </h3>
      <p>
        主要区别就是标准版只能一台电脑运行,而进阶版可以手机和平板点单,支持多设备连接.
        进阶版的硬件性能也比标准版提高.
      </p>
      <h3>
        冷默餐饮系统跟奇思有什么区别
      </h3>
      <p>
        都是一样的，冷默是我之前测试的名字，刚开始起步 使用这个名字， 现在开始使用正式，并改名为 奇思科技
      </p>
    </div>



 

    <p>

      
    </p>
    
  </div>
</template>

<script>
export default {
  name: 'support',
  data() {
    return {
     
    };
  },
  computed: {
  
  },
  watch: {
   
  },
  methods: {
    updateMessage() {
  
    }
  },
  created() {
  
  },
  mounted() {
  
  },
  beforeMount() {

  },
  updated() {
   
  },
  beforeUpdate() {

  },
  beforeDestroy() {
 
  },
  destroyed() {

  },
  beforeRouteEnter(to, from, next) {

    next();
  },
  beforeRouteLeave(to, from, next) {
 
    next();
  }
};
</script>

<style lang="less"  scoped>
.support{
  min-height: 600px;
}
</style>

