<template>
  <div id="home">


    <div v-if="this.$store.state.screenStat == 'small'" class="hamburger-icon">
      <i-hamburger-button v-if="!isNavOpen" theme="outline" size="48" @click="toggleNav" />
      <i-close v-if="isNavOpen" theme="outline" size="48" @click="toggleNav" />
    </div>



    <!-- 导航栏菜单 -->
    <transition v-if="this.$store.state.screenStat == 'small'" appear name="fade">
      <div v-if="isNavOpen" class="nav-menu-small">

        <div class="nav-title-small">
          <el-input v-if="false" class="search-input-small" type="text" placeholder="Search" />
          <el-select class="language-bar-small" v-model="language" placeholder="请选择语言">
            <el-option class="noselect" v-for="(item, key) in languageList" :key="key" :label="item.name"
              :value="item.lang">
            </el-option>
          </el-select>
        </div>



        <el-menu :default-active="$route.path" class="nav-small" mode="vertical" router @select="handleSelect">

          <el-menu-item :index="navItems[0].route">{{ $t(navItems[0].label) }}</el-menu-item>
          <el-submenu class="small-sub-menu" :index="navItems[1].route">
            <template slot="title"> {{ $t(navItems[1].label) }}</template>
            <el-menu-item v-for="(item, index2) in navItems[1].children" :key="index2" :index="item.route">{{
              $t(item.label)
            }}</el-menu-item>

          </el-submenu>

          <el-submenu class="small-sub-menu" :index="navItems[2].route">
            <template slot="title"> {{ $t(navItems[2].label) }}</template>
            <el-menu-item v-for="(item, index2) in navItems[2].children" :key="index2" :index="item.route">{{
              $t(item.label)
            }}</el-menu-item>

          </el-submenu>

          <el-menu-item :index="navItems[3].route">{{ $t(navItems[3].label) }}</el-menu-item>

        </el-menu>

        <!-- 导航栏内容 -->
        <!-- 这里放置你的导航菜单项目 -->
      </div>
    </transition>


    <div class="nav-container ">



      <!-- 添加 Logo -->
      <div class="nav-logo noselect">

        <img class="logo-img" src="../../../public/qisi.png" alt="">
      </div>


      <nav v-if="this.$store.state.screenStat == 'big'" class="navbar noselect">
        <el-menu :default-active="$route.path" class="nav" mode="horizontal" active-text-color="#409EFF" router
          @select="handleSelect">

          <el-menu-item class="nav-menu-item" :index="navItems[0].route">

            {{ $t(navItems[0].label) }}</el-menu-item>
          <el-submenu popper-append-to-body class="nav-menu-submenu" :index="navItems[1].route">
            <template slot="title"> <span class="nav-menu-item">{{ $t(navItems[1].label) }}</span> </template>
            <el-menu-item class="nav-menu-item" v-for="(item, index2) in navItems[1].children" :key="index2"
              :index="item.route">
              {{
                $t(item.label)
              }}
            </el-menu-item>

          </el-submenu>

          <el-submenu popper-append-to-body :index="navItems[2].route">
            <template slot="title"><span class="nav-menu-item">{{ $t(navItems[2].label) }}</span> </template>
            <el-menu-item class="nav-menu-item" v-for="(item, index2) in navItems[2].children" :key="index2"
              :index="item.route">{{
                $t(item.label)
              }}</el-menu-item>

          </el-submenu>

          <el-menu-item class="nav-menu-item" :index="navItems[3].route">{{ $t(navItems[3].label) }}</el-menu-item>

        </el-menu>

      </nav>

      <!-- 添加搜索栏 -->
      <div v-if="this.$store.state.screenStat == 'big'" class="search-bar">

        <el-select v-if="isDesktop" class="language-bar noselect" v-model="language" placeholder="请选择语言">
          <el-option class="noselect language-item" v-for="(item, key) in languageList" :key="key" :label="item.name"
            :value="item.lang">
          </el-option>
        </el-select>


      </div>





    </div>

    <!-- 页面主题区域 -->
    <div class="main-container">
      <router-view></router-view>
    </div>

    <footer class="footer">
      <div class="footer-container">

        <div class="footer-main">
          <div class="footer-item">
            <h2>
              公司LOGO
            </h2>

          </div>

          <div class="footer-item">
            <h2>
              {{ $t('nav.goods') }}

            </h2>

            <ul>
              <li>
                <router-link to="/goods/classic">{{ $t('nav.classic') }}</router-link>
            
              </li>
              <li>
                <router-link to="/goods/advanced">{{ $t('nav.avanced') }}</router-link>
        
              </li>
              <li>
                <router-link to="/goods/store-classic">{{ $t('nav.storeclassic') }}</router-link>
        
              </li>

            </ul>
          </div>

          <div class="footer-item">
            <h2>
              {{ $t('nav.support') }}
            </h2>

            <ul>
              <li>
                <router-link to="/service/support">{{ $t('nav.support') }}</router-link>
           </li>
              <li>
                <router-link to="/service/tech">{{ $t('nav.tech') }}</router-link>
           </li>
              <li>
                <router-link to="/service/tryOnline">{{ $t('nav.try') }}</router-link>
               </li>
              <li>
                <router-link to="/service/drive">{{ $t('nav.drive') }}</router-link>
         </li>
              <li>
                <router-link to="/service/remotely">{{ $t('nav.remotely') }}</router-link>
           </li>
              <li>
                <router-link to="/service/clientdisplay">{{ $t('nav.clientDisplay') }}</router-link>
           </li>
            </ul>
          </div>

          <div class="footer-item">
            <h2>
              {{ $t('nav.about') }}
            </h2>

            <ul>
              <li>
                <router-link to="/about">{{ $t('nav.about') }}</router-link>
              </li>
            </ul>
            <ul>
              <li>
                <router-link to="/about">{{ $t('footer.contact') }}</router-link>
              </li>
            </ul>

          </div>
        </div>




        <div class="footer-right">
          <p>&copy; 2023 LENGMO SYSTEM. All rights reserved.</p>

        </div>

      </div>




    </footer>






  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      isCollapse: false,
      // imgPaht: '../../../static/img/',
      //服务器
      imgPaht: '../../static/img/',
      ini: {
        email: 'conta@sushi.com',
        signin: 'inicia sesion',
        registred: 'Registrame',
      },
      languageList: [
        {
          lang: 'zh',
          name: 'Chinese',
        },
        {
          lang: 'es',
          name: 'Spanish',
        },
        /*   {
             lang:'en',
             name:'English',
           }*/
      ],
      language: 'zh', // 默认语言
      navItems: [
        {
          label: 'nav.home',
          route: '/home',
          path: "/home",
        },
        {
          label: 'nav.goods', route: '/goods', children: [
            { label: 'nav.classic', route: '/goods/classic' },
            { label: 'nav.avanced', route: '/goods/advanced' },
            { label: 'nav.storeclassic', route: '/goods/store-classic' },
          ]
        },
        {
          label: 'nav.services', route: '/service', children: [
            { label: 'nav.support', route: '/service/support' },
            { label: 'nav.tech', route: '/service/tech' },
            { label: 'nav.try', route: '/service/tryOnline' },
            { label: 'nav.drive', route: '/service/drive' },
            { label: 'nav.remotely', route: '/service/remotely' },
            { label: 'nav.clientDisplay', route: '/service/clientdisplay' },

          ]
        },
        { label: 'nav.about', route: '/about' },



      ],
      activeIndex: '0',
      isDesktop: window.innerWidth > 500,
      isNavOpen: false, // 导航栏是否打开的状态




    }
  },
  created() {




  },
  computed: {
    navMenuStyle() {
      return {
        left: this.isNavOpen ? '0' : '-70%', // 如果导航栏打开，显示在屏幕内；否则隐藏在屏幕左侧
      };
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {

    language(val) {
      this.$i18n.locale = val
    }


  },
  methods: {
    toggleNav() {
      this.isNavOpen = !this.isNavOpen; // 切换导航栏的显示状态
    },
    handleResize() {
      this.isDesktop = window.innerWidth > 500;
      console.log(this.isDesktop)
    },
    navigate(route) {
      this.$router.push(route);
    },
    handleSelect() {

    }


  },
}
</script>


<style lang="less" scoped>
#home {}




.nav-container {
  margin: auto;
  height: 100px;
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;


  .nav-logo {
    font-size: 30px;
    font-family: sans-italic;
    color: var(--main-logo-color);

  }

  .logo-img {
    width: 140px;
    

  }

  .search-bar {

    display: flex;
    flex-direction: row;



    .language-bar {
      width: 150px;
      margin-left: 20px;



    }

    /deep/ .language-bar .el-input__inner {
      background-color: inherit !important;


    }

  }

  .navbar {
    background-color: var(--nav-background-color);
    padding: 10px;
    display: flex;
    flex-direction: row;


    .el-menu {
      background-color: var(--nav-background-color);
    }



  }





}


.nav-menu-submenu .nav-menu-item {
  font-size: var(--nav-item-font-size);
  color: var(--nav-item-color);
  font-weight: var(--nav-item-weight);
}


.nav-menu-item {
  font-size: var(--nav-item-font-size);
  color: var(--nav-item-color);
  font-weight: var(--nav-item-weight);
}




.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-list {
  list-style-type: none;
  padding: 0;
  display: flex;
}

.navbar-item {
  margin-right: 20px;
  font-size: 40px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s;
}

.navbar-item:hover,
.navbar-item.active {
  color: #007bff;
}

.navbar-item:hover {
  /* 设置鼠标悬停时的背景颜色为蓝色 */
  background-color: #007bff;
  color: #fff;
}



.main-container {
  


}


///footer
.footer {
  margin-top: 50px;
  /* 蓝色背景 */
  color: #000000;
  /* 白色文字 */
  padding: 20px;
  display: flex;
  flex-direction: column;

  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 32px 0px;
    height: 300px;

    .footer-main {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;

      justify-content: space-between;

      .footer-item {
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: left;



        ul {
          list-style: none;
          /* 取消列表项的默认样式（如圆点或数字） */
          padding: 0;
          /* 取消列表的内边距 */
          margin: 0;
          /* 取消列表的外边距 */

          li {
            height: 40px;
          }

          a {
            text-decoration: none;
            color: #000;
            font-size: 18px;

          }

          a:hover {
            text-decoration: underline;
            color: #0056b3;
            /* 自定义悬停时的颜色 */
          }

        }






      }
    }
  }

  .footer-right {
    display: flex;

    width: 100%;
    justify-content: left;
    font-weight: 300;
    color: #9f9c9c
  }



}
















@media (max-width:991px) {

  #home {
    font-size: 22px;
    max-width: 100%;
    overflow: hidden;

  }


  .nav-logo {
    margin: auto;
  }

  .main-container {
    padding: 20px;
  }

  .nav-container {

    height: 80px;
    display: flex;
    flex-direction: row;




  }

  /deep/ .language-bar-small .el-input__inner {
    background-color: inherit ;


  }

  .hamburger-icon {
    position: absolute;
    top: 20px;
    /* 距离顶部的距离 */
    right: 20px;
    /* 距离右侧的距离 */
    cursor: pointer;
    z-index: 2;
    /* 保证在导航栏之上 */
    color: #000;
  }

  .nav-menu-small {
    position: absolute;
    width: 70%;
    /* 占据整个宽度 */
    height: 100%;
    z-index: 1;
    /* 保证在导航栏之上 */

    ///背景色
    background-color: var(--nav-small-background-color);


    .el-menu-item {
      background-color: var(--nav-small-background-color);
    }

    .el-submenu__title {
      background-color: var(--nav-small-background-color);
    }

    .el-submenu {
      background-color: var(--nav-small-background-color);
    }


    //悬浮色
    .el-menu-item:hover {
      background-color: var(--nav-small-hover-background-color);
    }

    .small-sub-menu .el-submenu:hover {
      background-color: var(--nav-small-hover-background-color);
    }


    .small-sub-menu .el-submenu__title:hover {
      background-color: var(--nav-small-hover-background-color);
    }




    .is-active {
      background-color: var(--nav-small-hover-background-color);
    }




    .nav-title-small {
      height: 80px;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;

      width: 100%;
      margin: 10px;




      .search-input-small {
        width: 100%;
      }

      .language-bar-small {
        width: 25%;
        margin-left: 50px;







      }

    }


  }

  .footer {
    width: 100%;
    height: 100%;

    .footer-container {
      width: 100%;
      height: 100%;

      .footer-main {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;



        .footer-item {
          width: 50%;
          height: 100%;

        }


      }

    }

    .footer-right {
      font-size: 16px;
    }
  }





  .fade-enter-active,
  .fade-leave-active {
    transition: transform 0.5s;
    /* 过渡效果持续时间为 0.5 秒，可以根据需要调整 */
  }

  .fade-enter,
  .fade-leave-to {
    transform: translateX(-100%);
    /* 元素初始位置在左边，消失时平移到左侧 */
  }

  .fade-leave-active.fade-leave-to {
    transform: translateX(-100%);
    /* 消失时平移到左侧 */
  }


}
</style>