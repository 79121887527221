<template>
  <div class="submain-div">
    <div class="try-online-restaurant">
      
   
  
        <el-button class="button">
          <a  target="_blank" :href="link">餐饮系统在线尝试</a>
        </el-button>

    

   
    </div>
    <div>
      
请点击"餐饮系统在线尝试"以体验系统。网页版几乎与实体软件相同，达到了99%的相似度，但请注意，某些操作只能在实际软件上进行测试。
    </div>

    <div v-if="false" class="try-online-restaurant">
      
   
  
      <el-button class="button">
        <a  target="_blank" :href="link2">百货系统在线尝试</a>
      </el-button>

  

 
  </div>
  
    
  </div>
</template>

<script>
export default {
  name: 'tryOnline',
  data() {
    return {
      link:'http://www.qisi.es:10300',
      link2:'http://www.qisi.es:10301',
     
    };
  },
  computed: {
  
  },
  watch: {
   
  },
  methods: {
    updateMessage() {
  
    },
    downloadZip(name) {
      this.$http.get('/api/downdLoad/Zip/' + name, { responseType: 'blob' })
        .then(response => {
          console.log(response)
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name + '.zip');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error => {
          console.error('Error downloading ZIP file:', error);
        });

    }
  },
  created() {
  
  },
  mounted() {
  
  },
  beforeMount() {

  },
  updated() {
   
  },
  beforeUpdate() {

  },
  beforeDestroy() {
 
  },
  destroyed() {

  },
  beforeRouteEnter(to, from, next) {

    next();
  },
  beforeRouteLeave(to, from, next) {
 
    next();
  }
};
</script>

<style lang="less"  scoped>
.try-online-restaurant{
  width: 100%;
  height: 650px;
  background-image:url("../../assets/img/logo4.png") ;
  background-position: center center; /* 图像在元素内居中 */
  background-size: cover; /* 图像将被拉伸以填充元素的整个背景 */
  background-repeat: no-repeat; /* 禁止图像重复平铺 */
  display: flex;

  justify-content: center;
  margin-bottom: 50px;
}

.button{
  margin-top: 20px;
  width: 220px;
  height: 60px;
  border-radius: 30px;
  border-style: solid;
  border-width: 5px;
  border-color: var(--main-logo-color);
}

a{
  
  text-decoration: none;
  color: var(--main-logo-color);
  font-size: 22px;
}

@media (max-width:991px) {

  .try-online-restaurant{
  max-width: 100%;
  background-image:url("../../assets/img/logo4.png") ;
  background-position: center center; /* 图像在元素内居中 */
  background-size: cover; /* 图像将被拉伸以填充元素的整个背景 */
  background-repeat: no-repeat; /* 禁止图像重复平铺 */
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}



}

</style>

