<template>
  <div class="submain-div">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'service',
  data() {
    return {
      message: 'Hello Vue!',
      reversedMessage: ''
    };
  },
  computed: {
   
  },
  watch: {
 
  },
  methods: {

  },
  created() {
 
  },
  mounted() {

  },
  beforeMount() {

  },
  updated() {

  },
  beforeUpdate() {

  },
  beforeDestroy() {
  
  },
  destroyed() {

  },
  beforeRouteEnter(to, from, next) {

    next();
  },
  beforeRouteLeave(to, from, next) {
  
    next();
  }
};
</script>

<style lang="less"  scoped>
h1 {
  color: blue;
}

button {
  margin-top: 10px;
}
</style>

