<template>
  <div id="classic submain-div">
  
    <div>
      <h1>奇思餐饮进阶版</h1>
      <p>对比基础版本 屏幕升级为ASUS 21.5寸触摸屏 电脑CPU升级为LACK N95 支持多设备连接 手机 屏幕 电脑</p>
      <p>支持手机点单,平板点单</p>
      <p style="color: red;font-size: 30px;">暂未上市</p>
    </div>

    <div>
      <img class="img" src="../../assets/img/restaurant-adv/adv0.jpg" alt="">
    </div>
    <div>
      <img class="img" src="../../assets/img/restaurant-adv/adv1.jpg" alt="">
    </div>
    <div>
      <img class="img" src="../../assets/img/restaurant-adv/adv2.jpg" alt="">
    </div>

    <div>
      <img class="img" src="../../assets/img/restaurant-adv/平板1.jpg" alt="">
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'classic',
  data() {
    return {

    };
  },
  computed: {

  },
  watch: {

  },
  methods: {
    updateMessage() {

    }
  },
  created() {

  },
  mounted() {

  },
  beforeMount() {

  },
  updated() {

  },
  beforeUpdate() {

  },
  beforeDestroy() {

  },
  destroyed() {

  },
  beforeRouteEnter(to, from, next) {

    next();
  },
  beforeRouteLeave(to, from, next) {

    next();
  }
};
</script>

<style lang="less"  scoped>
#classic {
  width: 100%;
  height: 100%;


  .title {

    font-size: 24px;
    color: var(--main-color-2);



  }
}

.img{
  max-width: 100%;
  background-color: #fff;
}
</style>

