<template>
  <div class="submain-div">
    <h1 class="global-title">
      远程协助
    </h1>

    <p>
      向日葵远程软件 <a target="_blank" href="https://www.qisi.es/download/sun.zip">点击下载</a>
    </p>

    <p>

    <h2>
      向日葵教程
    </h2>

    <div>
      点击下载解压后 ,安装，安装好后
      向我们工作人员发生截图,请保管好自己的密码,不要泄露给其他人
    </div>
    <div>

      <div>
        <img class="img" src="../../assets/img/sunlogin.png" alt=""> 点击安装包进行安装

      </div>
      <div>
        <img class="img" src="../../assets/img/sunlong-1.png" alt=""> 点击立即安装
      </div>

      <div>
        <img class="img" src="../../assets/img/sunlogin-2.png" alt=""> 打开向日葵
      </div>

      <div>
        <img class="img" src="../../assets/img/sunlong3.png" alt=""> 把本设备识别码和验证码发送给工作人员
        为了安全起见 请保管好自己的密码
      </div>


    </div>





    </p>


  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'remotely',
  data() {
    return {

    };
  },
  computed: {

  },
  watch: {

  },
  methods: {
    updateMessage() {

    },


    downloadZip(name) {
  let totalSize = 0; // 文件的总大小

  // 获取文件的总大小
  axios.head('/api/downdLoad/Zip/' + name, {
  headers: {
    Range: 'bytes=0-' // 这里设置 Range 头部，表示下载整个文件
  }
})
    .then(response => {
      console.log(response)
      const contentRange = response.headers['content-range'];
      if (contentRange) {
        totalSize = contentLength
        console.log('文件总大小：' + totalSize);
        startDownload();
      } else {
        console.error('无法获取文件总大小');
      }
    })
    .catch(error => {
      console.error('Error getting file size:', error);
    });

  const startDownload = () => {
    const chunkSize = 1024 * 1024; // 分段大小，这里设置为1MB，你可以根据需要进行调整
    let startByte = 0;
    let endByte = chunkSize - 1;

    const downloadNextChunk = async () => {
      try {
        const response = await axios.get('/api/downdLoad/Zip/' + name, {
          responseType: 'arraybuffer',
          headers: {
            Range: `bytes=${startByte}-${endByte}`,
          },
        });

        // 处理分段数据
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}-${startByte}-${endByte}.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // 更新下一段的起始和结束字节
        startByte = endByte + 1;
        endByte = Math.min(startByte + chunkSize - 1, totalSize - 1); // 避免超出文件末尾

        // 继续下载下一段
        if (startByte < totalSize) {
          console.log("继续下载下一段");
          downloadNextChunk();
        } else {
          console.log("下载完成");
        }
      } catch (error) {
        console.error('Error downloading ZIP file:', error);
      }
    };

    // 开始第一段下载
    downloadNextChunk();
  };
}



  },
  created() {

  },
  mounted() {

  },
  beforeMount() {

  },
  updated() {

  },
  beforeUpdate() {

  },
  beforeDestroy() {

  },
  destroyed() {

  },
  beforeRouteEnter(to, from, next) {

    next();
  },
  beforeRouteLeave(to, from, next) {

    next();
  }
};
</script>

<style lang="less"  scoped>

.img{
  max-width: 100%;
}


</style>

