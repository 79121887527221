import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Message } from 'element-ui'

import store from '../store'
import staticRoute from './staticRoute'
//import whiteList from './whiteList'


Vue.use(VueRouter)





var permissionList = []

function initRoute(router) {
    return new Promise((resolve) => {
  
        if (permissionList.length == 0) {
            console.log("没有权限数据，正在获取")

            store.dispatch('getPermissionList').then((res) => {
                console.log("权限列表生成完毕")
                    //  console.log(JSON.stringify(res))
                permissionList = res
                res.forEach(function(v) {
                    let routeItem = router.match(v.path)
                    if (routeItem) {
                        routeItem.meta.permission = v.permission ? v.permission : []
                        routeItem.meta.name = v.name
                    }
                })
                resolve()
            })

        } else {
            console.log("已有权限数据")
            resolve()
        }
    })
}



const router = new VueRouter({
        routes: staticRoute,
         //mode:'history'
        
    })
    // 挂载路由导航守卫
router.beforeEach((to, from, next) => {
    // to 将要访问的路径
    // from 代表从哪个路径跳转而来
    // next 是一个函数，表示放行
    //     next()  放行    next('/login')  强制跳转


    return next() //测试模式 全部放行
    if (to.path === '/MyHome') return next()
    else if (to.path === '/MyHome/Login') return next()
    else if (to.path === '/MyHome/ForgotPassword') return next()
    else if (to.path === '/MyHome/registred') return next()
    else if (to.path === '/MyHome/Cart') return next()
    else if (to.path === '/404') return next()


    //获取koten 没有token跳转到登录界面
    const tokenStr = window.sessionStorage.getItem('token')
    if (!tokenStr) return next('/MyHome/Login')
    else {
        console.log(to)
        console.log(to.fullPath)
        initRoute(router).then(() => {
                let isPermission = false //先是没权限
                console.log("进入权限判断")
                     //console.log(JSON.stringify(permissionList))
                    ///测试 所有通过
                    //    isPermission = true
                permissionList.forEach((v) => {
                     console.log(v.path)

                    // 判断跳转的页面是否在权限列表中
                    if (v.path == to.path) {
                        isPermission = true
                    }
                    // next()
                })





                // 没有权限时跳转到401页面
                if (!isPermission) {
                    console.log('无权限')
                    Message.error('无权限！')
                    next({ path: "/MyHome", replace: true })
                } else {
                    console.log('通过')
                    next()
                }
            })
            // next()
    }

})



router.afterEach(() => {
    NProgress.done(); // 结束Progress
})


export default router