<template>
  <div class="login_container">
    <div class="login_box" v-if="this.$store.state.screenStat == 'big'">
      <!--头像区域<img src=".../assets/logo.png" alt=""> -->

      <div class="avatar_box"><img src="../../assets/img/logo/qisi-sq.png" alt="" /></div>

      <!-- 登录表单区域 -->
      <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" label-width="0px" class="login_form">
        <!-- 用户名 -->
        <el-form-item prop="username">
          <el-input clearable v-model="loginForm.username" placeholder="email"
            prefix-icon="iconfont icon-user"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input v-model="loginForm.password" clearable prefix-icon="iconfont icon-3702mima" placeholder="Clave"
            show-password @keyup.enter.native="login" type="password"></el-input>
        </el-form-item>
        <!-- 按钮区域 -->
        <el-form-item class="btns">
          <el-button v-if="false" type="primary" @click="registred">注册</el-button>
          <router-link v-if="false" to="/Home/ForgotPassword">忘记密码</router-link><!-- 
         <el-button type="primary" @click="forgotPassword">忘记密码</el-button>-->

          <el-checkbox v-model="checked" :label="$t('mainPage.rememberPassword')" name="type"></el-checkbox>
          <el-button round class="class1-color" type="primary" @click="login">{{ $t('mainPage.login') }}</el-button>


          <el-button round v-if="false" type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
   <el-form-item >
         <el-button type="primary" @click="downloadZip">
          下载ZIP
         </el-button>
        </el-form-item>

      </el-form>
    </div>


    <div class="login_box-small" v-if="this.$store.state.screenStat == 'small'">
      <!--头像区域<img src=".../assets/logo.png" alt=""> -->

      <div class="avatar_box-small"><img src="../../assets/img/logo/qisi.png" alt="" /></div>

      <!-- 登录表单区域 -->
      <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" label-width="0px" class="login_form_small">
        <!-- 用户名 -->
        <el-form-item prop="username">
          <el-input class="input-small" v-model="loginForm.username" placeholder="email" prefix-icon="iconfont icon-user"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input class="input-small" v-model="loginForm.password" prefix-icon="iconfont icon-3702mima" placeholder="Clave"
            type="password"></el-input>
        </el-form-item>
        <!-- 按钮区域 -->
        <el-form-item class="btns">

          <el-checkbox v-model="checked" :label="$t('mainPage.rememberPassword')"  name="type"></el-checkbox>
          <el-button class="button-small class1-color" type="primary" @click="login">{{ $t('mainPage.login') }}</el-button>

        </el-form-item>
      </el-form>
    </div>






  </div>
</template>


<script>
export default {
  name: 'mylogin',
  data() {
    //https://www.cnblogs.com/dengsir39/archive/2022/07/11/16467487.html  记住密码和加密
    //http://t.zoukankan.com/Jimc-p-10275201.html cookie
    return {
      // 这是登录表单的数据绑定对象
      loginForm: {
        username: '',
        password: '',
      },
      checked: false,

      // 这是表单的验证规则对象
      loginFormRules: {
        // 验证用户名是否合法
        username: [
          { required: true, message: '请输入登录名称', trigger: 'blur' },
          { min: 3, max: 55, message: '长度在 3 到 10 个字符', trigger: 'blur' },
        ],
        // 验证密码是否合法
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { min: 4, max: 45, message: '长度在 5 到 45 个字符', trigger: 'blur' },
        ],
      },
    }
  },
  mounted() {
    //  console.log(window.sessionStorage.getItem('token'))

    if (window.sessionStorage.getItem('token'))
      // console.log(this.$store.state.userInfo)
      this.$router.push('/Back')
    // console.log(this.$cookies.keys())

    if (this.$cookies.isKey('username')) {
      this.loginForm.username = this.$cookies.get("username")
      this.checked = true

    }
    if (this.$cookies.isKey('password')) {
      this.loginForm.password = Base64.decode(this.$cookies.get("password"))// base64解密

    }

  },
  methods: {
    // 点击重置按钮，重置登录表单
    resetLoginForm() {
      // console.log(this);
      this.$refs.loginFormRef.resetFields()
    },
    login() {


      //设置cookie
      if (this.checked) {
        this.$cookies.set('username', this.loginForm.username, 60 * 60 * 24 * 30)

        this.$cookies.set('password', Base64.encode(this.loginForm.password), 60 * 60 * 24 * 30)
      }
      else {
        this.$cookies.remove('username')
        this.$cookies.remove('password')
      }
      //this.$cookies.set('username',this.loginForm.username)


      // console.log('登录')
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return
        // console.log("发送登录")
        const { data: res } = await this.$http.post('api/login/', this.loginForm)
        //  console.log("res"+ res)
        if (res.status != 0) return this.$message.error(res.message)

        this.$message.success(res.message)

        // 1. 将登录成功之后的 token，保存到客户端的 sessionStorage 中
        //   1.1 项目中出了登录之外的其他API接口，必须在登录之后才能访问
        //   1.2 token 只应在当前网站打开期间生效，所以将 token 保存在 sessionStorage 中
        window.sessionStorage.setItem('token', res.token)
        // console.log(res)
        this.$store.commit('saveUserInfo', res)

        // 2. 通过编程式导航跳转到后台主页，路由地址是 /home

        this.$router.push('/Back')
      })
    },
    registred() {
      this.$router.push('/MyHome/registred')
    },
    forgotPassword() {
      this.$router.push('/MyHome/ForgetPassword')
    },
    downloadZip(){


        
       this.$http.get('/api/downdLoad/Zip',  { responseType: 'blob' })
        .then(response => {
          console.log(response)
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'your-zip-file.zip');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error => {
          console.error('Error downloading ZIP file:', error);
        });
    
    }
  },
}
</script>

<style lang="less"   scoped>




.login_container {
  background-color: #2b4b6b;
  height: 100%;
}

.login_box {
  width: 450px;
  height: 300px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.login_box-small {
  width: 900px;
  height: 600px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .username {
    font-size: 65px;
  }


  .button-small {
    width: 220px;
    height: 160px;
    font-size: 65px;
    border-radius:70px;
  }


}


.avatar_box {
  height: 130px;
  width: 130px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #eee;
  }
}

.avatar_box-small {
  height: 600px;
  width: 600px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
 
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #eee;
  }
}

.login_form_small{
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  font-size: 55px;
 
}


.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.btns {
  display: flex;
  justify-content: flex-end;
}
</style>

