<template>
  <div class="client-display submain-div">
    <div @click="entry(item)" class="client-display-item" v-for="(item, index) in data" :key="index">
      <div class="display-top">
        <img class="client-img" :src="item.img" alt="">
      </div>


      <div class="display-bot">
        <span>
          {{ item.name }}
        </span>
        <span>
          {{ item.description }}
        </span>

        <span>{{ item.location }}</span>
      </div>



    </div>
  </div>
</template>

<script>

import img1 from '../../assets/img/nousomni2.jpg'
import img2 from '../../assets/img/nouSomni2_2.jpg'
import img3 from '../../assets/img/valencia2.jpg'
export default {
  name: 'client-Display',
  data() {
    return {
      data: [
        {
          name: "NOU SOMNI ",
          img: img1,
          description: "Bar Restaurant ",
          location: "Barcelona ",
          path: "/service/clientdisplay/nousomni"

        },
        {
          name: "NOU SOMNI II ",
          img: img2,
          description: "Bar Restaurant",
          location: "Barcelona",
          path: "/service/clientdisplay/nousomniII"
        },
        {
          name: "ALADAYA",
          img: img3,
          description: "Bar Restaurant",
          location: "Valencia",
          path: "/service/clientdisplay/valencia"
        },
      ],
      imgPath: "../../assets/img/"
    };
  },
  computed: {

  },
  watch: {

  },
  methods: {
    updateMessage() {

    },
    entry(item) {
      this.$router.push(item.path)

    }
  },
  created() {

  },
  mounted() {

  },
  beforeMount() {

  },
  updated() {

  },
  beforeUpdate() {

  },
  beforeDestroy() {

  },
  destroyed() {

  },
  beforeRouteEnter(to, from, next) {

    next();
  },
  beforeRouteLeave(to, from, next) {

    next();
  }
};
</script>

<style lang="less"  scoped>
.client-display {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  /* 子元素的最大宽度不超过父元素的宽度 */
  height: 100%;


  .client-display-item {

    width: 48%;
    padding: 40px;
    margin: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;


  }

  .display-top {
    width: 100%;
    height: 80%;
    overflow: hidden;
    display: flex;
    /* 使用 flex 布局 */
    justify-content: center;
    /* 在水平方向上居中 */
    align-items: center;
    /* 在垂直方向上居中 */

    .client-img {
      max-width: 100%;
      /* 图片最大宽度为容器宽度 */
      max-height: 100%;
      /* 图片最大高度为容器高度 */
      display: block;
      /* 移除图片默认的行内元素属性 */
      margin: auto;
      /* 用于水平居中 */


    }
  }

  .display-bot {
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    

  }


}

@media (max-width:991px) {



  .client-display {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;


    .client-display-item {
      width: 100%;
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      border: none;
      border: solid 1px;


      .display-top {
        width: 100%;
       
        max-height: 500px;
        padding: 0;
        margin: 0;
      }

      .display-bot{
        height: 200px;
        display: flex;
        flex-direction: column;
      }

      .client-img {
        margin: 0;
        padding: 0;
        max-width: 100%;
        /* 图像的最大宽度不超过其容器宽度 */
        max-height: 100%;
        /* 图像的最大高度不超过其容器高度 */

      }


    }

  }



}
</style>

