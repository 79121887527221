module.exports = {
    menu: {
        home: "casa"
    },
    content: {
        main: "this is content"
    },
    homeMenuList: [{
            name: 'INICI',
            id: '1',
            path: '/Home/INICIO',
        },
        {
            name: 'CARTA',
            id: '2',
            path: '/Home/Cart',
        },

        {
            name: 'CONTACTE',
            id: '3',
            path: '/Home/CONTACTO',
        },
        {
            name: 'NOSALTRES',
            id: '4',
            path: '/Home/NOSOTROS',
        },
    ],
    home: {
        register: 'registre',
        login: 'Iniciar Sessió',
    },
    cart: {
        add: 'afegir',
        myShopCart: 'Mi carrito',
    }
}