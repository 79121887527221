<template>
  <div id="classic submain-div">
  
    <div>
      <h1>奇思百货系统</h1>
      <p>调试中 目前拥有将近5000左右的百货数据</p>
      <p style="color: red;font-size: 30px;">暂未上市</p>
    </div>

    <div>
      <img class="img" src="../../assets/img/store/store1.png" alt="">
    </div>
   
 
    
  </div>
</template>

<script>
export default {
  name: 'classic',
  data() {
    return {

    };
  },
  computed: {

  },
  watch: {

  },
  methods: {
    updateMessage() {

    }
  },
  created() {

  },
  mounted() {

  },
  beforeMount() {

  },
  updated() {

  },
  beforeUpdate() {

  },
  beforeDestroy() {

  },
  destroyed() {

  },
  beforeRouteEnter(to, from, next) {

    next();
  },
  beforeRouteLeave(to, from, next) {

    next();
  }
};
</script>

<style lang="less"  scoped>
#classic {
  width: 100%;
  height: 100%;


  .title {

    font-size: 24px;
    color: var(--main-color-2);



  }
}

.img{
  max-width: 100%;
  background-color: #fff;
}
</style>

