

//数据分类

// 01- Home
//02 -Caja
// 03 Goods
// 04-datos
//05-system info
// 06 admin


/////////////
//主页 01
import MyHome from '../components/01-MyHome/MyHome.vue'
import MyLogin from '../components/01-MyHome/MyLogin.vue'

import about from '../components/01-MyHome/about.vue'
import services from '../components/01-MyHome/services.vue'

import goods from '../components/01-MyHome/goods.vue'

import home from '../components/01-MyHome/home.vue'


import support from '../components/01-MyHome/support.vue'
import tech from '../components/01-MyHome/tech.vue'
import tryOnline from '../components/01-MyHome/tryOnline.vue'
import drive from '../components/01-MyHome/drive.vue'
import remotely from '../components/01-MyHome/remotely.vue'

import classic from '../components/01-MyHome/classic.vue'

import clientDisplay from '../components/01-MyHome/clientDisplay.vue'
import clientDisplayNouSomni from '../components/01-MyHome/clientDisplayNouSomni.vue'
import clientDisplayNouSomniII from '../components/01-MyHome/clientDisplayNouSomniII.vue'
import clientDisplayValencia from '../components/01-MyHome/clientDisplayValencia.vue'

import advanced from '../components/01-MyHome/advanced.vue'

import restaurant from '../components/01-MyHome/restaurant.vue'
//百货
import storeClassic from '../components/01-MyHome/departmentstore.vue'

















//更多


const staticRoute = [
    //{ path: '*', redirect: '/MyHome' },
    
    {
        path: '/',
        redirect: '/home',
        component: MyHome,
        children: [
            { path: '/Login', component: MyLogin },
            { path: '/about', component: about },
            { path: '/service', component: services,
            children:[
                { path: '/service/support', component: support },
                { path: '/service/tech', component: tech },
                { path: '/service/tryOnline', component: tryOnline },
                { path: '/service/drive', component: drive },
                { path: '/service/remotely', component: remotely },
                { path: '/service/clientdisplay', component: clientDisplay },
                { path: '/service/clientdisplay/nousomni', component: clientDisplayNouSomni },
                { path: '/service/clientdisplay/nousomniII', component: clientDisplayNouSomniII },
                { path: '/service/clientdisplay/valencia', component: clientDisplayValencia },
                

           
                
            ]
        },

            { path: '/goods', component: goods,
            children:[
                { path: '/goods/classic', component: classic },
                { path: '/goods/advanced', component: advanced },
                { path: '/goods/store-classic', component: storeClassic },
                
            ] },
         
            { path: '/home', component: home },
          

        ]
    },
    { path: '/restaurant', component: restaurant },
]

export default staticRoute