module.exports = {




nav:{
    home:'Home',
    about:'',
    services:'servició',
    contact:'contact',
    goods:'goods',
    about:'about',



    tech:'tutorial',
    support:'support',
    try:'try',
    drive:'drive',
    remotely:'remotely',
    clientDisplay:"Cliente Presenta",
    storeclassic:"百货标准版",

    classic:'classic',
    avanced:"advanced"

},
footer:{
    contact:"contact"
}


   
}