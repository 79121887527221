<template>
  <div class="submain-div">

    <div class="drive-item">

      <div class="drive-left">
        <img class="drive-xprint-img" src="../../assets/img/xprinter.png" alt="xprint">

      </div>

      <div class="drive-right">
        <h1 class="global-title">驱动下载</h1>
        <p>

          打印机驱动: <a target="_blank" href="https://www.qisi.es/download/xprintDrive.zip">点击下载</a>
        </p>

        <p>
          打印机软件: <a target="_blank" href="https://www.qisi.es/download/xprintControl.zip">点击下载</a>
        </p>
      </div>




    </div>
    <div class="drive-item">

      <div class="drive-left">
        <img class="drive-xprint-img" src="../../assets/img/Unykach.jpg" alt="xprint">

      </div>

      <div class="drive-right">
        <h1 class="global-title">驱动下载</h1>
        <p>
          打印机驱动: <a target="_blank" href="https://www.qisi.es/download/unykachDrive.zip">点击下载</a>
        </p>

        <p>
          打印机软件: <a target="_blank" href="https://www.qisi.es/download/unykachControl.zip">点击下载</a>
        </p>
      </div>



    </div>
    <div class="drive-item">

      <div class="drive-left">
        <img class="drive-xprint-img" src="../../assets/img/Equip.jpg" alt="xprint">

      </div>

      <div class="drive-right">
        <h1 class="global-title">驱动下载</h1>
        <p>
          打印机驱动: <a target="_blank" href="https://www.qisi.es/download/equipDrive.zip">点击下载</a>
        </p>

        <p>
          打印机软件: <a target="_blank" href="https://www.qisi.es/download/equipControl.zip">点击下载</a>
        </p>
      </div>



    </div>
    <div class="drive-item">
      <div class="drive-right">
        <h1 class="global-title">手机端下载</h1>
        <p>
          手机端: <a target="_blank" href="https://www.qisi.es/download/QISIV2096.apk">点击下载</a>
        </p>

        <p>
          手机端进阶: <a target="_blank" href="https://www.qisi.es/download/QISIV012096.apk">点击下载</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'drive',
  data() {
    return {

    };
  },
  computed: {

  },
  watch: {

  },
  methods: {
    updateMessage() {

    },
    async downloadZip(name) {


      const filename = name + '.zip';
      const downloadUrl = "localhost:" + filename;

      try {
        // 使用Axios进行GET请求以获取文件数据
        const response = await this.$http.get(downloadUrl, {
          responseType: 'blob', // 设置响应类型为二进制数据
        });

        // 创建一个Blob对象表示文件数据
        const blob = new Blob([response.data]);

        // 创建一个临时的URL以便于下载
        const url = window.URL.createObjectURL(blob);

        // 创建一个<a>标签并设置相关属性以触发下载
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;

        // 模拟点击<a>标签以触发下载
        link.click();

        // 释放Blob的URL对象
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('文件提取失败:', error);
      }

    }



  },
  created() {

  },
  mounted() {

  },
  beforeMount() {

  },
  updated() {

  },
  beforeUpdate() {

  },
  beforeDestroy() {

  },
  destroyed() {

  },
  beforeRouteEnter(to, from, next) {

    next();
  },
  beforeRouteLeave(to, from, next) {

    next();
  }
};
</script>

<style lang="less" scoped>
.drive-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;


  .drive-left {
    width: 60%;

  }

  .drive-right {
    width: 40%;
  }
}

.drive-xprint-img {
  max-width: 100%;
  max-height: 400px;
}


@media (max-width:991px) {

  .drive-item {
    flex-direction: column;

    .drive-left {
      width: 100%;

    }

    .drive-right {
      width: 100%;
    }
  }

}
</style>
