<template>
  <div class="client-display submain-div">
    <div>
      客户介绍,软件安装照片下面客户照片
    </div>
    <div class="dispaly-item">
      <img src="../../assets/img/nouSomni1.jpg" alt="">
      
    </div>
    <div class="dispaly-item">
      <img class="img-class" src="../../assets/img/nousomni3.jpg" alt="">
      
    </div>


  </div>
</template>

<script>

export default {
  name: 'NouSomni',
  data() {
    return {
     
    };
  },
  computed: {
  
  },
  watch: {
   
  },
  methods: {
    updateMessage() {
  
    }
  },
  created() {
  
  },
  mounted() {
  
  },
  beforeMount() {

  },
  updated() {
   
  },
  beforeUpdate() {

  },
  beforeDestroy() {
 
  },
  destroyed() {

  },
  beforeRouteEnter(to, from, next) {

    next();
  },
  beforeRouteLeave(to, from, next) {
 
    next();
  }
};
</script>

<style lang="less"  scoped>
.client-display{
  display: flex;
  flex-direction: column;
  
}

.display-item{
  width: 100%;

}

.img-class{
  max-width: 100%;
}

@media (max-width:991px) {
  
}


</style>

