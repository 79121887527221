<template>
  <div id="classic submain-div">

    <div>
      <img class="img" src="../../assets/img/classic1.png" alt="">
    </div>
    <div>
      <img class="img" src="../../assets/img/classic2.png" alt="">
    </div>
    <div>
      <img class="img" src="../../assets/img/classic3.png" alt="">
    </div>
    <div>
      <p>
        欢迎使用我们的软件产品 - 标准版！我们的标准版是一款专为餐饮行业设计的高效、稳定且灵敏的收银系统，旨在为您的业务提供最佳解决方案。以下是我们产品的一些亮点和特点：

      </p>
      <p class="title">
        高效性：
      </p>

      <p>我们的标准版收银系统经过精心设计，旨在提升您的业务效率。通过优化的界面和操作流程，您可以更快速地完成订单处理和支付操作，为客户提供更快捷的服务。
        稳定性：</p>

      <p>
        我们注重系统的稳定性和可靠性。不论是繁忙的高峰时段还是平稳的业务运营，我们的系统都能够保持稳定，确保您的业务不受干扰。

      </p>

      <p class="title">
        灵敏度：
      </p>

      <p>
        随着触摸显示器的应用，我们的系统具备出色的灵敏度。您可以轻松地通过触摸屏幕来处理订单、调整菜单、进行支付等操作，提升操作的便捷性和快速性。
        
      </p>
      
      <p class="title">
        硬件配置：
      </p>

      <p>
        我们的标准版配备了一套精选的硬件设备，包括15寸华硕触摸显示器，小型电脑，收钱柜，有线打印机，鼠标和键盘。这些设备的组合确保您拥有一个完整且高效的收银工作站。
        用户友好界面：
      </p>

      <p>
        我们的界面设计简洁直观，使您的员工能够快速上手。无论是新手还是有经验的员工，都可以轻松地使用我们的系统。
        
      </p>
      <p class="title">
        实时数据分析：
      </p>

      <p>
        我们的标准版收银系统还配备了实时数据分析功能，让您能够随时随地了解业务运营情况。通过数据报表和图表，您可以深入了解销售趋势、热门菜品以及其他关键业务指标。
       
      </p>

      <p class="title">
        客户支持：
      </p>

      <p>
        我们提供优质的客户支持，无论您在使用过程中遇到什么问题，我们的专业团队都会及时为您解答和提供帮助。
        我们的标准版收银系统将帮助您实现高效、稳定和灵敏的业务运营，提升客户体验和您的业务表现。无论您是餐饮行业的新手还是经验丰富的从业者，我们都有信心为您提供出色的收银解决方案！

      </p>


    </div>
  </div>
</template>

<script>
export default {
  name: 'classic',
  data() {
    return {

    };
  },
  computed: {

  },
  watch: {

  },
  methods: {
    updateMessage() {

    }
  },
  created() {

  },
  mounted() {

  },
  beforeMount() {

  },
  updated() {

  },
  beforeUpdate() {

  },
  beforeDestroy() {

  },
  destroyed() {

  },
  beforeRouteEnter(to, from, next) {

    next();
  },
  beforeRouteLeave(to, from, next) {

    next();
  }
};
</script>

<style lang="less"  scoped>
#classic {
  width: 100%;
  height: 100%;


  .title {

    font-size: 24px;
    color: var(--main-color-2);



  }
}

.img{
  max-width: 100%;
  background-color: #fff;
}
</style>

